/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/Container';
import Hero from '../../components/Hero';
import { H1, H2, H4 } from '../../components/StyledHeaders';
import StandardSection from '../../components/StandardSection';
import css from '@emotion/css';
import Button from '../../components/Button';
import HowWeWork from '../../components/HowWeWork';
import NonStretchedImage from '../../utils/NonStretchedImages';

const HiddenBox = styled(Box)`
  display: block;
  ${(props) => props.smDown && css`@media (max-width: ${props.theme.breakpoints[0]}) {display: none;}`}
`;

const WebDevelopment = (props) => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "mobile.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2560, quality: 100) {
						...GatsbyImageSharpFluid
						presentationWidth
					}
				}
			}
			sideImage: file(relativePath: { eq: "mobile-dev.png" }) {
				childImageSharp {
					fluid(maxWidth: 512, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Mobile Development" />

			<Hero height={[ '40vh', '50vh' ]}>
				<Container>
					<div style={{ maxWidth: 720 }}>
						<H1>Mobile Development</H1>
					</div>
				</Container>
				<NonStretchedImage {...data.placeholderImage.childImageSharp} />
			</Hero>

			<StandardSection>
				<Container>
					<Flex flexWrap="wrap" mx={[ -2, -3 ]} py={'60px'}>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>Our technology</H2>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								We stand out for using the most optimal technologies, to mention one of the technologies
								we use react native for the framework, guaranteeing scalability and performance in your
								application, our commitment is to offer products and services of the highest quality.
								The designers work together with our clients to meet their needs as well as the demands
								of the market, obtaining a competitive and optimal design.
							</p>
						</Box>
						<HiddenBox width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto" />

						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<Img
								fluid={data.sideImage.childImageSharp.fluid}
								style={{ width: 320, margin: '0 auto' }}
							/>
						</Box>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>What do we do?</H2>
							<H4 secondary left>
								One code, all platforms
							</H4>
							<p style={{ fontSize: 16, textAlign: 'justify', marginTop: 16 }}>
								We have a highly technical team of experts in JavaScript. We are at the forefront of the
								newest and rapidly evolving frameworks. Your company can be sure that our continuous
								delivery approach will help you reach the market faster at a quarter of the cost and
								allow you to find your best product and market setting.
							</p>

							<H4 secondary left>
								Accelerate your project
							</H4>
							<p style={{ fontSize: 16, textAlign: 'justify', marginTop: 16 }}>
								Our native design and development approach means less time and effort! We will help you
								reach the market twice as fast, while maintaining our continuous delivery approach and
								allowing you to find your best product and market setting.
							</p>

							<H4 secondary left>
								Experience
							</H4>
							<p style={{ fontSize: 16, textAlign: 'justify', marginTop: 16 }}>
								Our years of experience support our work as well as each of our satisfied customers.
							</p>
						</Box>

						<Box width={[ 1 ]} px={[ 1, 3 ]} style={{ textAlign: 'center' }}>
							<Button primary="true" as={Link} to={`${props.uri}#contact-us`}>
								Request more info
							</Button>
						</Box>
					</Flex>
				</Container>

				<HowWeWork uri={props.uri} />
			</StandardSection>
		</Layout>
	);
};

export default WebDevelopment;
